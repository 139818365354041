import { Typography } from '@material-ui/core';
import React from 'react';
import { Link} from 'react-router-dom';


const NotFound = () => {

    return (
        <div style={{display: 'flex', justifyContent: 'center', height: '90vh'}} >
            <div style={{alignSelf: 'center', display: 'flex'}}>
                <Typography variant="h1" bold>404</Typography>
                <span style={{width: '0.5rem', backgroundColor: 'black', margin: '0 2rem'}}/>
                <Typography variant="h5" style={{alignSelf: 'end'}}>You are not supposed to be here, <Link to="/">Go back</Link></Typography>
            </div>
        </div>
    )
}

export default NotFound;