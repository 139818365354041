import { FETCH_OLD } from '../constants/actionTypes'

const event_reducer = (oldEvents = [], action) => {
    switch (action.type) {
        case FETCH_OLD:
            return action.payload.slice().sort((a,b)=>new Date(b.beginDate) - new Date(a.beginDate));
        default:
            return oldEvents;
    }
}

export default event_reducer;