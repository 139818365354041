import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { HashRouter, Link, Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';

import reducers from './reducers';
import DefaultLoad from './components/Loading/DefaultLoad'
import NotFound from './components/Loading/NotFound';

const App = lazy(() => import('./App'));
const Unsubscribe = lazy(() => import('./components/SubscribeDialog/Unsubscribe'));
const ValidateMaster = lazy(() => import('./components/ValidatePage/ValidateSignup'));

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        {/* <Helmet>
          <title>Vilkkoni | Events</title>
        </Helmet> */}
        <HashRouter basename="/">
            <Suspense fallback={<DefaultLoad />}>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route path="/unsubscribe" component={Unsubscribe} />
                    <Route path="/validate" component={ValidateMaster} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);